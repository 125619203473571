
<template>
  <div>
    <div class="carelist">
      <div class="header">
        <el-button size="mini" plain type="primary" @click="()=>this.$router.back()">{{$t("care.back")}}</el-button>
        <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="getList()"></el-button>
        <el-button size="mini" plain type="primary" icon="el-icon-plus"  @click="handleAdd()"></el-button>
        <el-button size="mini" :loading="pulling" plain type="primary"  @click="handlePull()">同步</el-button>
        <div class="search">
          <span>{{$t("care.search_title")}}：</span>
          <div>
            <el-select @change="onSearch" clearable size="small" style="width: 100px" v-model="search.status" :placeholder="$t('care.value_str.select')">
              <el-option :label="$t('care.tpl.status_on')" :value="1"></el-option>
              <el-option :label="$t('care.tpl.status_off')" :value="0"></el-option>
            </el-select>
          </div>
          <div>
            <el-input size="small"  :placeholder="$t('care.search_title')" v-model="search.search" class="input-with-select">
              <el-button  @click="onSearch" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>

      </div>
      <div class="content">
        <el-empty :description="$t('care.notSetCare')" v-if="hadGetlist&&tableData.length===0"></el-empty>
        <el-table
            v-else
            v-loading="spinning"
            class="table"
            row-key="id"
            :data="tableData"
            :height="tableHeight"
            highlight-current-row
            @row-dblclick="onDblclick"
        >
          <el-table-column
              prop="name"
              :label="$t('care.tpl.name')"
          >
            <template slot-scope="scope">
              <div class="avatar">
                <a-tag style="margin-left: 8px;" color="blue">{{scope.row.name}}</a-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="language"
              :label="$t('care.tpl.language')"
          >
            <template slot-scope="scope">
              {{scope.row.language}}
            </template>
          </el-table-column>
          <el-table-column
              prop="status"
              :label="$t('care.tpl.status')"
          >
            <template slot-scope="scope">
              <a-tag v-if="scope.row.status===1" color="blue">{{$t('care.tpl.status_on')}}</a-tag>
              <a-tag v-else >{{$t('care.tpl.status_off')}}</a-tag>
            </template>
          </el-table-column>

          <el-table-column
              prop="body"
              :label="$t('care.tpl.body')"
          >
            <template slot-scope="scope">
              {{JSON.parse(scope.row.data).components.find(item=>item.type==='BODY').text}}
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('care.ops')"
          >
            <template slot-scope="scope">
              <div class="options">
                <div>
                  <el-button
                      :loading="scope.row.delete_loading"
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="handleDelete(scope.$index, scope.row)"></el-button>
                </div>
                <div>
                  <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      @click="onDblclick(scope.row)">预览</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            small
            layout="prev, pager, next"
            :current-page.sync="page"
            @current-change="pageChange"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
        title="範本編輯"
        :visible.sync="addCareVisible"
        append-to-body>
      <el-form :model="form" :rules="rules" :label-width="formLabelWidth" style="max-width: 800px">
        <el-alert
            title="暫時不支援新增模板頭部，如有需要，請自行到Facebook官方網頁添加"
            type="warning"
            effect="dark"
            show-icon
            center
            :closable="false"
            style="margin-bottom: 40px;"
        >
        </el-alert>
        <el-form-item :label="$t('care.careform.name')" prop="name">
          <div class="formval">
            <el-input placeholder="只能由小寫字母+底線+數字組成"  v-model="form.name"></el-input>
          </div>
        </el-form-item>

        <el-form-item :label="$t('care.careform.lang')" prop="language">
          <div class="formval">
            <el-select v-model="form.language" placeholder="language">
              <el-option
                  v-for="item in langOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item :label="$t('care.careform.body_text')">
          <div class="formval">
            <el-input :placeholder="'body模板需包含{{1}},此參數會被替換成客服發送文本'"  v-model="form.body_text"></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('care.careform.body_example')">
          <div class="formval">
            <el-input :placeholder="'提供一個替換{{1}}的範例文本'"  v-model="form.body_example"></el-input>
          </div>
        </el-form-item>

        <el-form-item :label="$t('care.careform.footer_test')">
          <div class="formval">
            <el-input  v-model="form.footer_test"></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('care.careform.bottons')">
          <div class="formval" style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;">
           <div  style="margin-bottom: 10px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;">
             <div style="width:150px;font-size: large; ">類型</div>
             <div style="width:150px;font-size: large; ">按鈕文字</div>
             <div style="width:150px; font-size: large;">電話/網站</div>
           </div>
            <div
                style="margin-bottom: 10px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;"
                 v-for="(btn,index) in form.bottons"
                 :key="JSON.stringify(btn)">
              <el-select v-model="btn.type" style="width:150px; ">
                <el-option
                    v-for="item in btntypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
              </el-select>
              <el-input style="width:150px; " v-model="btn.text"></el-input>
              <el-input placeholder="如 +85239087888"  style="width:180px; " v-if="btn.type==='PHONE_NUMBER'" v-model="btn.phone_number"></el-input>
              <el-input placeholder="如 https://www.topkee.com.hk/"  style="width:180px; " v-if="btn.type==='URL'"  v-model="btn.url"></el-input>
              <el-button
                  style="margin-left: 10px;"
                  type="danger"
                  icon="el-icon-delete"
                  @click="deleteBtn(index)"></el-button>
            </div>
            <el-button
                icon="el-icon-plus"
                @click="addBtn()"></el-button>
          </div>
        </el-form-item>



      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addCareVisible = false">{{$t('care.messenger.cancel')}}</el-button>
        <el-button :loading="addLoading" type="primary" @click="save">{{$t('care.messenger.sure')}}</el-button>
      </div>
    </el-dialog>
    <TplCard v-if="cardVisible" :visible="cardVisible" :tpl="viewRow" />

  </div>

</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import careMixin from "../../../mixins/careMixin";
import {addMessengerTpl, deleteMessengerTpl, getMessengerTpls, pullMessengerTpls} from "../../../libs/apis/messenger";
import TplCard from "@/pages/admin/components/TplCard.vue";

export default{
  data(){
    return {
      cardVisible:false,
      addCareVisible:false,
      addLoading:false,
      pulling:false,
      visible:false,
      spinning:false,
      hadGetlist:false,
      tableHeight:document.documentElement.clientHeight - 100,
      tableData:[],
      deleteRow:{},
      groupEditVisible:false,
      addCareLoading:false,
      formLabelWidth:'120px',
      group:{},
      search:{
        search:null,
        status:null,
      },
      form:{
        header:null,
        name: null,
        body_text:"你好，我係TopkeeMedia聯絡你。{{1}}。如果想了解更多信息，請直接回復。",
        body_example:"我想向你確認一下，你是否已經收到我們的服務報價，想問你對我們的方案報價，有沒有疑問需要我進一步解答",
        footer_test:null,
        bottons:[
          {
            "type": "QUICK_REPLY",
            "text": "咨詢方案"
          }
        ],

        language: "zh_HK",
        category: "MARKETING",
      },
      rules:{
        name: [
          { required: true, message: '請填寫模板名', trigger: 'blur' }
        ],
        body_text: [
          { required: true, message: '請填寫body', trigger: 'blur' }
        ],
        body_example: [
          { required: true, message: '請填寫body參數範例', trigger: 'blur' }
        ],

      },
      page:1,
      total:0,
      selectRow:null,
      viewRow:{},
      btntypeOptions:[
        {
          label:'快速回覆',
          value:'QUICK_REPLY',
        },{
          label:'造訪網站',
          value:'URL',
        },{
          label:'撥打電話',
          value:'PHONE_NUMBER',
        },
      ],
      langOptions:[
        {
          label:'简体中文',
          value:'zh_CN',
        },{
          label:'繁體中文',
          value:'zh_HK',
        },{
          label:'English',
          value:'en',
        },
      ],
    };
  },
  mounted: function(){
    this.$nextTick(function () {
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight = document.documentElement.clientHeight - 100;
      }
    });
    this.getList();

  },
  components: {
    TplCard,
  },
  computed: {
  },
  methods: {
    addBtn(){
      this.form.bottons.push({
        "type": "QUICK_REPLY",
        "text": "我知道了"
      });
    },
    deleteBtn(index){
      this.form.bottons.splice(index,1);
    },
    onDblclick(row){
      this.cardVisible=false;
      this.viewRow={}
      this.$nextTick(function () {
        console.log(row,'---------')
        this.viewRow=row.data
        this.cardVisible=true;
      })

    },
    onSearch(){
      this.page=1;
      this.getList();
    },
    pageChange(page){
      this.page=page;
      this.getList();
    },
    editCare(row){
      this.careForm=row;
      this.addCareVisible=true;
    },
    async save(){
      try {

        this.addLoading=true;
        if(
            !this.form.body_text
            ||!this.form.name
            ||!this.form.body_example
        ){
          this.$message.warning('參數不全')
          return;
        }
        let data={};
        data.name=this.form.name;
        data.language=this.form.language;
        data.category='MARKETING';
        data.components=[];
        data.components.push({
          "type": "BODY",
          "text": this.form.body_text,
          "example": {
            "body_text": [
              [
                this.form.body_example
              ]
            ]
          }
        })
        if(this.form.footer_test){
          data.components.push({
            "type": "FOOTER",
            "text": this.form.footer_test,
          })
        }
        if(this.form.bottons.length){
          data.components.push({
            "type": "BUTTONS",
            "buttons": [].concat(this.form.bottons),
          })
        }
        console.log(JSON.stringify(data),4)
        await addMessengerTpl(this.messenger_id,data);
        this.addLoading=false;
        this.addCareVisible=false;
        message.success(this.$t('care.opsSuccess'));
        this.handlePull();
      }catch (e) {
        this.addLoading=false;
        message.error(e.data?.message);
        throw e;
      }

    },
    async getList(){
      this.spinning=true;

      const rs=await getMessengerTpls(this.messenger_id,{
        filter:this.search,
        page:{
          number:this.page,
        },
        sort:['-updated_at']
      });
      this.tableData = rs.map(item=>{
        item.delete_loading=false;
        return item;
      });

      this.total= rs.length
      this.spinning=false;
    },
    handleAdd(){
      // this.careForm={
      //   id:null,
      //   name:null,
      //   avatar:null,
      //   receive_able:0,
      // };
      this.addCareVisible=true;
    },
    async handlePull(){
      message.info('正在同步模板')
      this.pulling=true;
      await pullMessengerTpls(this.messenger_id);
      this.pulling=false;

      message.success('成功同步模板')
      this.getList();
    },

    handleDelete(index,row){
      let that=this;
      this.$confirm('此操作將永久刪除該資料, 是否繼續?', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        row.delete_loading=true;
        await deleteMessengerTpl(that.messenger_id,row.id)
        this.$message({
          type: 'success',
          message: '刪除成功!'
        });
        row.delete_loading=false;
        that.handlePull();
      })
    },
  },
  mixins:[careMixin]
};
</script>
